import { render, staticRenderFns } from "./loker.vue?vue&type=template&id=3e9244a8&"
import script from "./loker.vue?vue&type=script&lang=js&"
export * from "./loker.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCard,QCardSection,QInput,QBtn,QTooltip,QSeparator,QBtnGroup,QImg,QBadge,QMenu,QList,QItem,QItemSection,QCardActions,QDialog,QEditor,QFile,QIcon,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QInput,QBtn,QTooltip,QSeparator,QBtnGroup,QImg,QBadge,QMenu,QList,QItem,QItemSection,QCardActions,QDialog,QEditor,QFile,QIcon})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
