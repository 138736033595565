


import FETCHING from '../../library/fetching'
import DATA_MASTER from '../../library/dataMaster'

export default {
  data() {
    return {
      statusBtn : true,

      form : {
        id : '',
        status : '',
        judul : '',
        gaji : '',
        isi : '',
        file : null,
      },

      // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db : '',
      listKecamatan : '',
      listDesKel : '',
      // ====================================== CONTOH AUTOCOMPLETE ====================================
      


     
      list_data : [],
      list_desa : [],

      page_first: 1,
      page_last: 0,
      page_limit: 10,
      cari_value : "",
      file_old : "",
      cek_load_data : true,
      file_path: this.$store.state.url.URL_APP + "uploads/",

      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_lihat : false,
      btn_add: false,


      FETCHING : FETCHING,
      DATA_MASTER : DATA_MASTER,
    }
  },
  methods: {
    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_LOKER + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value,
              // kecamatan_id : this.form.kecamatan_id,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              // console.log(res_data);
      });
    },

    getProfil : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_PROFIL + "getProfil", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              
          })
      })
          .then(res => res.json())
          .then(res_data => {
              
              // console.log(res_data);
      });
    },

    checkStatus : function(){
      // this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_PROFIL + "getProfil", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              
          })
      })
          .then(res => res.json())
          .then(res_data => {
              
            console.log(res_data[0].status);
            
            var status = res_data[0].status;

            if (status == 0 || status == 2) {
              this.statusBtn = true;
            } else {
              this.statusBtn = false;
            }
      });

      // return true;
    },


    addData : function() {
      var formData = new FormData();
      formData.append("status", this.form.status);
      formData.append("judul", this.form.judul);
      formData.append("gaji", this.form.gaji);
      formData.append("isi", this.form.isi);
      formData.append("file", this.form.file);

      fetch(this.$store.state.url.URL_LOKER + "addData", {
          method: "POST",
          headers: {
            // "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
          this.getView();
      });
    },


    editData : function(){
      var formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("status", this.form.status);
      formData.append("judul", this.form.judul);
      formData.append("gaji", this.form.gaji);
      formData.append("isi", this.form.isi);
      formData.append("file", this.form.file);
      formData.append('file_old', this.file_old);

      fetch(this.$store.state.url.URL_LOKER + "editData", {
          method: "POST",
          headers: {
            // "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
          this.getView();
      });
    },

    removeData : function(E){
      fetch(this.$store.state.url.URL_LOKER + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },

    selectData : function(data){
      this.form.id = data.id;
      this.form.status= data.status;
      this.form.judul= data.judul;
      this.form.gaji= data.gaji;
      this.form.isi = data.isi;
      this.form.file = data.file;
      this.file_old = data.file;
    },


    // ====================================== CONTOH eDOC ====================================
    onClickx(data){
      alert(data)
    },
    // ====================================== CONTOH eDOC ====================================

  // ====================================== CONTOH AUTOCOMPLETE ====================================
    // filter_kecamatan : function (val, update) {
    //     update(() => {
    //       if (val === '') {}
    //       else {FETCHING.getKecamatan(val)}
    //     })
    // },

    // filter_des_kel : function (kecamatan_id, update) {
    //     update(() => {
    //       if (kecamatan_id === '') {}
    //       else {FETCHING.getDesKel(this.form.kecamatan_id)}
    //     })
    // },

    // ====================================== CONTOH AUTOCOMPLETE ====================================








    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },
        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx;
        },
        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },


    // ====================================== PAGINATE ====================================

  },

  mounted () {
    this.getView();
    this.getProfil();
    this.checkStatus();
  },
}
